.actionButtons {
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: 25vh;
}

.alert {
  float:left;
  font-size: small;
}

#bodyRow {
  min-height: 50vh;
}

button {
  margin: 5px;
}

#chooseProdRow {
  margin-left: 0;
}

#comments {
  height: 110px;
}

.container {
  min-height: 100vh;
  min-width: 100vw;
  background-color:lightblue;
}

#headerRow {
  height: 5rem;
}

.importBtn {
  width: 210px;
}

.loader {
  text-align: center;
}

.loginForm {
  width: 200px;
  margin: auto;
  text-align: center;
}

#logo {
  float: right;
  height: 40px;
  width: 80px;
  display: inline;
  margin: 10px;
}

.orderForm {
  text-align: center;
  left: 50%;
  position: absolute;
  -webkit-transform: translate3d(-50%, 0, 0);
  -moz-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
}

#scanNumber {
  width: 100%;
  height: 60px;
}

.smallInput {
  width: 200px;
}

#totalAmountLabel {
  font-size: 20px;
}

#unit {
  width: 70px;
}